
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        


















































.generic-carousel {
  position: relative;

  ::v-deep {
    .carousel__controls {
      margin-top: 0;

      @include mq($from: l) {
        position: absolute;
        z-index: 12;
        top: 50%;
        justify-content: space-between;
        width: 100%;
        translate: 0 -50%;
      }
    }

    .carousel__prevnext {
      --prevnext-offset: -3.5rem;

      @include mq($from: l) {
        /* stylelint-disable-next-line max-nesting-depth */
        &.prev {
          translate: -50% 0%;
        }

        /* stylelint-disable-next-line max-nesting-depth */
        &.next {
          translate: 50% 0%;
        }
      }

      @include mq($from: xl) {
        --prevnext-offset: -5rem;
      }

      @include mq($from: xxl) {
        --prevnext-offset: -6rem;
      }

      @include mq($from: xxxl) {
        --prevnext-offset: -8rem;
      }
    }
  }
}
